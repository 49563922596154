
import { Prop, Vue, Component, PropSync } from 'vue-property-decorator';
import { selectionObject } from '@/api/dioe-public-api';
import { tagModule } from '@/store/modules/tags';

@Component({
  name: 'TokenField',
  components: {}
})
export default class TokenField extends Vue {
  @Prop({ type: String, default: 'Anfrage eingeben' }) readonly label!: string;
  @Prop({ type: String, default: '' }) readonly header!: string;
  @Prop({ type: String, default: '' }) readonly hint!: string;
  @Prop({ type: String, default: 'mdi-plus' }) readonly appendIcon!: string;
  @Prop({ type: String, default: '#FF0000' }) readonly color!: string;
  @PropSync('selElements') readonly selectedElements!: selectionObject[];

  itemState = [
    { text: 'genau', val: 'genau' },
    { text: 'enthält', val: 'muss' },
    { text: 'nicht', val: 'nicht' }
  ];
  itemCase = ['case-sensitive', 'case-insensitive', 'RegEx'];

  state = 'genau';
  caseSen = 'case-sensitive';
  sppos = '';

  inputModel: string = '';

  validInput: boolean = true;

  get spposItems() {
    return tagModule.allSppos;
  }

  rules = {
    regexp: (value: string) => {
      const pattern = /^\/.*?\/[gimy]{0,4}$/;
      return pattern.test(value) || 'Ungültige RegEx';
    }
  };

  addToken() {
    if (!this.validInput) return;
    this.selectedElements.push({
      val: this.inputModel,
      state: this.state,
      case: this.caseSen,
      sppos: this.sppos === null ? '' : this.sppos
    });
    this.inputModel = '';
    this.sppos = '';
    this.$emit('update:selElements', this.selectedElements);
  }

  updated() {
    if (!this.state || this.state === '') this.state = 'muss';
    if (!this.caseSen || this.caseSen === '') this.caseSen = 'case-sensitive';
  }
}
