var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',_vm._l((_vm.legendGlobal),function(leg,idx){return _c('div',{key:idx,staticStyle:{"margin-top":"10px"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(leg.type !== 3)?[_c('v-col',{attrs:{"align":"center","cols":"2"}},[_c('v-avatar',[(leg.symbol === 0)?[_c('icon-circle',{attrs:{"fillCol":_vm.convertHsl(leg.color),"strokeWidth":1}})]:(leg.symbol === 1)?[_c('img',{attrs:{"src":_vm.drawRect(20, leg.strokeWidth, _vm.convertHsl(leg.color), true)}})]:[_c('img',{attrs:{"src":_vm.drawTriangle(
                    20,
                    leg.strokeWidth,
                    _vm.convertHsl(leg.color),
                    true
                  )}})]],2)],1)]:_vm._e(),_c('v-col',[_vm._v("Name: "+_vm._s(leg.name))]),_c('v-col',[_vm._v("Typ: "+_vm._s(_vm.nameForSearchItems(leg.type)))]),(leg.type !== 3)?[_c('v-col',[_vm._v("Größe: "+_vm._s(leg.size)+" px")])]:[_c('v-col',{attrs:{"cols":"5"}},[_c('v-expansion-panels',{attrs:{"focusable":""}},_vm._l((leg.parameter),function(item,idx){return _c('v-expansion-panel',{key:idx},[_c('v-expansion-panel-header',[_c('div',[_c('v-avatar',[_c('icon-circle',{attrs:{"fillCol":_vm.convertHexToHsl(
                          item.color !== undefined ? item.color : '#F00'
                        ),"strokeWidth":leg.strokeWidth}})],1),_c('span',[_vm._v(_vm._s(item.name))])],1)]),_c('v-expansion-panel-content',[_c('ItemDescription',{attrs:{"item":item}})],1)],1)}),1)],1)]],2),_c('v-row',[_c('v-divider')],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }