
import { Component, PropSync, Vue, Prop, Watch } from 'vue-property-decorator';
import { tagModule } from '@/store/modules/tags';
import { legendMod } from '@/store/modules/legend';
import { transModule } from '@/store/modules/transcripts';
import { selectionObject, TagTree } from '@/api/dioe-public-api';
import TagView from '@/components/TagView.vue';
import TokenField from '@/components/TokenField.vue';
import LegendDescEdit from './LegendDescEdit.vue';
import ErhebungDropdown from './ErhebungDropdown.vue';

import draggable from 'vuedraggable';
import {
  Job,
  Parameter,
  LegendList,
  SearchItems,
  LegendGlobal,
  Symbols
} from '@/static/apiModels';
import * as LZ from 'lz-string';
import { generateID, hslToHex, selectColor } from '@/helpers/helper';
import IconCircle from '@/icons/IconCircle.vue';
import SymbolPicker from '@/components/SymbolPicker.vue';
import { aufgabenModule } from '@/store/modules/aufgaben';
import { expData } from '@/service/ExportBase';
import { messageHandler } from '@/store/modules/message';
import { VueEditor } from 'vue2-editor';
import { erhebungModule } from '@/store/modules/erhebungen';

@Component({
  components: {
    draggable,
    TagView,
    IconCircle,
    SymbolPicker,
    TokenField,
    VueEditor,
    LegendDescEdit,
    ErhebungDropdown
  },
  name: 'QueryTool'
})
export default class QueryCreator extends Vue {
  editLegendDialog: boolean = false;
  showTimeline: boolean = false;
  dialog: boolean = false;
  selectedItem = null;

  chips: any[] = [];
  chipIDs: number[] = [];

  focusLegend: LegendGlobal = {} as LegendGlobal;
  focusParameter: Parameter[] = [];
  editPar: Parameter = {} as Parameter;
  focusLegId: number = -1;

  paraName: string = '';
  paraLemma: string = '';
  selProject: number = 0;
  selTags: number[] = [];
  selToken: string[] = [];
  selMaxEducation: string = '';
  paraDesc: string = '';
  min: number = 0;
  max: number = 100;
  textToken: selectionObject[] = [];
  textLemma: selectionObject[] = [];

  formControl: {
    paraName: string;
    paraLemma: string;
    selProject: number;
    selTags: number[];
    symbol: number;
    selToken: string[];
    selMaxEducation: string;
    paraDesc: string;
    min: number;
    max: number;
    paraColor: {
      hex: string;
    };
    range: Array<number>;
    selMobility: string;
    selParents: string;
    selEducation: number;
    selGender: string;
    selJob: string;
  } = {} as any;

  @Prop(Number) readonly legendId: number | undefined;

  @Prop(Number) readonly focusItem: number | undefined;

  selMobility: string = '';
  selParents: string = '';
  selEducation: number = -1;
  selGender: string = '';
  selJob: string = '';
  selEducationAll: Job | null | undefined = null;
  legName: string = '';

  range = [18, 90];

  parColor: string = '#FF0000';

  token = ['Orthographisch', 'Phonetisch'];
  projects = ['PP11'];
  testItems = ['UND', 'ODER'];
  gender = ['Männlich', 'Weiblich'];
  parents = ['Herkunft'];
  job = ['Chemiker', 'Tischler', '...'];
  mobility = ['Auto', 'Zug', 'Pferd'];

  TM = tagModule;
  LM = legendMod;
  AM = aufgabenModule;
  TaM = transModule;
  MM = messageHandler;

  editMode: boolean = false;
  dragging: boolean = false;

  get jobs() {
    return this.TM.jobList;
  }

  get hasTagSelection() {
    return this.TM.tagSelection.length > 0;
  }

  get ausbildungsGrade() {
    return this.LM.ausbildungsGrad;
  }

  get tags() {
    return this.TM.tagList == null ? [] : this.TM.tagList;
  }

  get loading() {
    return this.TM.loading;
  }

  get erhLoading() {
    return erhebungModule.erhLoading;
  }

  get parameters() {
    return this.TM.parameters;
  }

  get queryLegend() {
    return this.LM.legend.filter((el) => el.type === SearchItems.Query);
  }

  get legends() {
    return this.TM.legends;
  }

  get globalLegend() {
    return this.LM.legend;
  }

  get legendLoad() {
    return this.LM.loading;
  }

  get teams() {
    return this.AM.teams;
  }

  get erhArten() {
    return erhebungModule.erhebungsarten;
  }

  updateDesc(id: string, val: string) {
    this.focusLegend.description = val;
    this.LM.editLegendByID(this.focusLegend);
  }

  deleteLegendEntry(el: LegendGlobal, idx: number | null) {
    this.showTimeline = false;
    this.LM.deleteLegendEntry(el, idx);
    this.focusLegend = {} as LegendGlobal;
    expData.removeEntry(el.id, el.name, el.type ? el.type : 0);
  }

  editLegName() {
    if (this.focusLegend.name !== '') this.LM.editLegendByID(this.focusLegend);
  }

  checkEducation(pk: number) {
    const found = this.jobs.find((pk) => pk === pk);
    this.selEducationAll = found;
  }

  remove(item: any) {
    this.chips.splice(this.chips.indexOf(item), 1);
    this.chips = [...this.chips];
  }

  viewLegend(idx: number, legend: LegendGlobal) {
    this.legName = legend.name;
    this.focusParameter = legend.parameter
      ? legend.parameter
      : ([] as Parameter[]);
    this.focusLegId = idx;
    this.focusLegend = legend;
    if (!this.showTimeline) this.showTimeline = !this.showTimeline;
  }

  deleteItem(id: number) {
    this.focusParameter.splice(id, 1);
  }

  editItem(curr: Parameter) {
    this.editMode = true;
    this.dialog = true;
    this.chipIDs = [];
    this.chips = [];
    this.formControl.paraName = curr.name;
    this.formControl.symbol = curr.symbol;
    this.formControl.selGender =
      curr.gender !== undefined ? (curr.gender ? 'Weiblich' : 'Männlich') : '';
    console.log(this.formControl.selGender);
    this.formControl.selParents = curr.parents ? curr.parents : '';
    this.formControl.selProject = curr.project ? curr.project : 0;
    this.formControl.paraDesc = curr.description ? curr.description : '';
    this.parColor = this.formControl.paraColor.hex = curr.color
      ? curr.color
      : '#F00';
    this.chipIDs = curr.erhArt ? curr.erhArt : [];
    this.chips = this.erhArten.filter((el) =>
      this.chipIDs.some((id) => id === el.id)
    );
    this.formControl.range = curr.ageRange;
    this.formControl.selEducation = curr.education ? curr.education : -1;
    this.formControl.selMaxEducation = curr.maxEducation
      ? curr.maxEducation
      : '';
    this.TM.setTagSelection(curr.tagList ? curr.tagList : []);
    this.formControl.paraDesc = curr.description ? curr.description : '';
    this.paraDesc = this.formControl.paraDesc;
    this.textToken = curr.textTokenList
      ? curr.textTokenList
      : ([] as selectionObject[]);
    this.textLemma = curr.lemmaList
      ? curr.lemmaList
      : ([] as selectionObject[]);
    this.editPar = curr;
  }

  createlegend() {
    const name = 'Unbennante Legende';
    const emptyLegend = {
      color: '#F00',
      size: 15,
      type: SearchItems.Query,
      content: null,
      stroke: true,
      strokeWidth: 1,
      parameter: null,
      layer: null,
      vis: true,
      name: name
    };
    this.parColor = emptyLegend.color;
    this.LM.addLegendEntry(emptyLegend);
    this.legName = name;
    this.focusParameter = [];
    this.focusLegend = this.globalLegend[this.globalLegend.length - 1];
    this.focusLegId = -1;
    this.showTimeline = true;
  }

  editParameter() {
    const id = this.editPar.id;
    if (this.focusLegend.parameter) {
      let par = this.focusLegend.parameter.find((el) => el.id === id);
      const parId = this.focusLegend.parameter.findIndex((el) => el.id === id);
      const ageRange = [this.formControl.range[0], this.formControl.range[1]];
      this.formControl.paraColor.hex = this.parColor;
      par = {
        name: this.formControl.paraName,
        content: null,
        visible: true,
        id: id,
        // @ts-ignore
        symbol: this.$refs.sym.symbol,
        erhArt: this.chips.map((val) => val.id),
        project: this.formControl.selProject,
        gender: this.formControl.selGender
          ? this.formControl.selGender === 'Weiblich'
            ? true
            : false
          : undefined, // Boolean
        education: this.formControl.selEducation, // ID
        maxEducation: this.formControl.selMaxEducation,
        parents: this.formControl.selParents,
        job: this.formControl.selJob,
        tagList: this.TM.tagSelection,
        token: this.formControl.selToken, //
        ageRange: ageRange, // Array with 2 numbers
        color:
          this.formControl.paraColor === null
            ? ''
            : this.formControl.paraColor.hex,
        description: this.paraDesc,
        textTokenList: this.textToken,
        lemmaList: this.textLemma
      };
      this.focusLegend.parameter[parId] = par;
      this.dialog = false;
      this.editMode = false;
      this.MM.setSuccessMsg({
        message: `Parameter ${this.formControl.paraName} wurde bearbeitet`,
        icon: 'mdi-info'
      });
      expData.pushNewLegend(this.focusLegend, -1);
      this.clearForm();
      this.TM.setTagSelection([]);
    }
  }

  openForm() {
    this.editLegendDialog = true;
  }

  clearForm() {
    if (this.$refs.form) {
      // @ts-ignore
      this.$refs.form.reset();
      this.formControl.range = this.range;
    }
    if (this.$refs.tagView) {
      // @ts-ignore
      this.$refs.tagView.clear();
      this.TM.setTagSelection([]);
    }
    this.formControl.paraName = '';
    this.formControl.selProject = 0;
    this.chips = [];
    this.textToken = [];
    this.textLemma = [];
    this.parColor = '#F00';
    this.paraDesc = '';
    this.chips = [];
  }

  createParameter(clear: boolean) {
    if (this.paraName === null) {
      this.paraName = '';
    }
    if (this.focusLegend) {
      const ageRange = [this.formControl.range[0], this.formControl.range[1]];
      this.formControl.paraColor.hex = this.parColor;
      const newParameter: Parameter = {
        name: this.formControl.paraName,
        content: null,
        id: generateID(),
        visible: true,
        erhArt: this.chips.map((val) => val.id),
        // @ts-ignore
        symbol: this.$refs.sym.symbol,
        project: this.formControl.selProject,
        gender: this.formControl.selGender
          ? this.formControl.selGender === 'Weiblich'
            ? true
            : false
          : undefined, // Boolean
        education: this.formControl.selEducation, // ID
        maxEducation: this.formControl.selMaxEducation,
        parents: this.formControl.selParents,
        job: this.formControl.selJob,
        tagList: this.TM.tagSelection,
        token: this.formControl.selToken, //
        textTokenList: this.textToken,
        lemmaList: this.textLemma,
        ageRange: ageRange, // Array with 2 numbers
        color:
          this.formControl.paraColor === null
            ? ''
            : this.formControl.paraColor.hex,
        description: this.paraDesc
      };
      if (!this.focusLegend.parameter) {
        this.focusLegend.parameter = [] as Parameter[];
      }
      this.focusLegend.parameter.push(newParameter);
      this.focusParameter = this.focusLegend.parameter;
      this.MM.setSuccessMsg({
        message: `Parameter ${this.formControl.paraName} wurde zu Legende ${this.focusLegend.name} hinzugefügt`,
        icon: 'mdi-info'
      });
      expData.pushNewLegend(this.focusLegend, -1);
      /*
      const para = LZ.compressToEncodedURIComponent(
        JSON.stringify(this.legends)
      );
      this.$router.push({
        path: "query",
        query: { parameters: para },
      });*/
      if (clear) this.dialog = false;
      this.paraDesc = '';
      this.clearForm();
      this.initFormControl();
      this.TM.setTagSelection([]);
    }
  }

  beforeCreate() {
    aufgabenModule.fetchAllTeams();
    tagModule.fetchJobs();

    if (!this.ausbildungsGrade || this.ausbildungsGrade.length < 0) {
      legendMod.fetchAusbildung();
    }
  }

  mounted() {
    if (this.$route.query.legend) {
      const id = this.$route.query.legend;
      const legend = this.queryLegend.filter((el) => el.id === id);
      this.viewLegend(-1, legend[0]);
    }
    if (this.$route.query.parameters) {
      const para = this.$route.query.parameters;
      let legend = undefined;
      if (typeof para === 'string') {
        const parameter = LZ.decompressFromEncodedURIComponent(para);
        legend = new Function(
          'return [' + parameter?.substring(1, parameter.length - 1) + '];'
        )();
        tagModule.clearLegend();
        legend.forEach((element: { name: string; parameter: Parameter[] }) => {
          tagModule.addLegend({
            name: element.name,
            parameter: element.parameter
          });
          // this.legName = element.name;
          tagModule.setParameters(element.parameter);
        });
      } else {
        console.log('Queryparameter has wrong format');
        if (this.$props.legendID) {
        }
      }

      if (legend === undefined) {
        if (this.$props.legendID) {
          const id = this.$props.legendID;
        }
      }
    }
    if (this.queryLegend.length > 0) {
      this.focusLegend = this.queryLegend[0];
    }
    if (!this.erhArten || this.erhArten.length === 0) {
      erhebungModule.fetchErhebungsArten();
    }
  }

  initFormControl() {
    const newColor = selectColor(null);
    const hexColor = hslToHex(newColor.h, newColor.s * 100, newColor.l * 100);
    this.parColor = hexColor;
    this.formControl.paraColor = { hex: hexColor };
    this.TM.setAutoCompleteLabel('Neuen Tag hinzufügen');
    this.TM.setChildrenTag([]);
  }

  created() {
    this.formControl.range = this.range;
    this.initFormControl();
  }

  destroyed() {
    console.log('Unmounting component');
    expData.pushNewLegend(this.focusLegend, -1);
  }
}
