
import { Prop, Vue, Component } from 'vue-property-decorator';
import { expData } from '@/service/ExportBase';
import { Hsl, SearchItems } from '@/static/apiModels';
import { legendMod } from '@/store/modules/legend';
import { messageHandler } from '@/store/modules/message';
import {
  nameForSearchItems,
  convertHslToStr,
  convertHexToHsl
} from '@/helpers/helper';
import IconCircle from '@/icons/IconCircle.vue';
import ItemDescription from './ItemDescription.vue';

import { drawRect, drawTriangle } from '@/helpers/MapCompute';

@Component({
  name: 'LegendDetails',
  components: { IconCircle, ItemDescription }
})
export default class LegendDetails extends Vue {
  LM = legendMod;

  get legendGlobal() {
    return this.LM.legend;
  }

  convertHexToHsl(col: string) {
    if (col.length > 7) {
      col = col.substring(0, 7);
    }
    const hsl = convertHexToHsl(col);
    return convertHslToStr(hsl[0] * 360, hsl[1], hsl[2]);
  }

  nameForSearchItems(val: SearchItems | null) {
    return val !== null ? nameForSearchItems(val) : val;
  }

  convertHsl(col: Hsl) {
    return convertHslToStr(col.h, col.s, col.l);
  }

  drawRect(size: number, border: number, color: string, encoded: boolean) {
    return drawRect(size, border, color, encoded, 1.0);
  }

  drawTriangle(size: number, border: number, color: string, encoded: boolean) {
    return drawTriangle(size, border, color, encoded, 1.0);
  }

  mounted() {}
}
