import { render, staticRenderFns } from "./TagView.vue?vue&type=template&id=111fb515&scoped=true&"
import script from "./TagView.vue?vue&type=script&lang=ts&"
export * from "./TagView.vue?vue&type=script&lang=ts&"
import style0 from "./TagView.vue?vue&type=style&index=0&id=111fb515&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "111fb515",
  null
  
)

export default component.exports