
import { Aset, TagTree } from '@/api/dioe-public-api';
import { SearchItems } from '@/static/apiModels';
import { aufgabenModule } from '@/store/modules/aufgaben';
import { phaeModule } from '@/store/modules/phaenomene';
import { Component, PropSync, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'PhaenAufgabenSearch'
})
export default class PhaenAufgabenSearch extends Vue {
  @Prop(String) readonly icon!: string;
  @Prop() readonly tagListFlat!: TagTree[];

  PM = phaeModule;
  AM = aufgabenModule;
  optionTab = 0;

  filterMenuValue: Array<{ type: SearchItems; content: any; name: string }> = [];
  phaenSelection = [];
  asetSelection = [];
  aufgabenSelection = [];

  filterOptionMenu = [
    { name: 'Phänomene', type: SearchItems.Phaen },
    { name: 'Tags', type: SearchItems.Tag }
  ];


  get phaen() {
    return this.PM.phaen;
  }

  get asetPhaen() {
    return this.PM.phaenAufgaben;
  }

  get aufgabenLoading() {
    return this.AM.loading;
  }

  changeFilterMenuValue(type: SearchItems, content: Array<any>, name: string) {
    this.filterMenuValue = [];
    content.forEach((e) => {
      this.filterMenuValue.push({ content: e, type: type, name: name });
    });
  }

  async searchAufgabeByPhaen() {
    this.optionTab = 1;
    const elements = this.phaenSelection.map((x) => this.filterMenuValue[x]);
    const cont = [] as number[];
    for (const ele of elements) {
      const e = ele.content as Aset;
      cont.push(e.id);
    }
    await this.PM.fetchAsetByPhaen({ ids: cont });
    // this.AM.fetchAufgabenSet({ ids: cont });
    if (this.asetPhaen.length === 1 && this.asetPhaen[0].id === -1) {
      this.optionTab = 2;
    }
  }

  clearFilterMenu() {
    this.filterMenuValue = [];
  }

  displayAufgabeOnMap() {
    const elements = this.aufgabenSelection.map((x) => this.asetPhaen.filter(el => el.id === -1)[0].aufgaben[x]);
    const cont = [] as number[];
    console.log(this.aufgabenSelection);
    for (const ele of elements) {
      cont.push(ele.id);
    }
    this.$emit('displayAufgabenOnMap', cont);
  }

  displayOnMap() {
    const elements = this.asetSelection.map((x) => this.asetPhaen[x]);
    const cont = [] as number[];
    for (const ele of elements) {
      const e = ele as Aset;
      cont.push(e.id);
    }
    this.$emit('displayAsetOnMap', cont);
  }

  openFilter(type: SearchItems) {
    this.phaenSelection = [];
    switch (type) {
      case SearchItems.Phaen:
        this.changeFilterMenuValue(
          SearchItems.Phaen,
          this.phaen,
          'bezPhaenomen'
        );
        break;
      case SearchItems.Tag:
        this.changeFilterMenuValue(
          SearchItems.Tag,
          this.tagListFlat,
          'tagName'
        );
        break;
    }
  }
}
