
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { legendMod } from '@/store/modules/legend';
import { aufgabenModule } from '@/store/modules/aufgaben';
import IconCircle from '@/icons/IconCircle.vue';
import ActionButtons from './ActionButtons.vue';
import { IGetPresetOrtTagResult } from '@/api/dioe-public-api/models/IGetPresetOrtTagResult';
import { tagModule } from '@/store/modules/tags';
import { expData } from '@/service/ExportBase';

import * as L from 'leaflet';
import { LegendGlobal, Hsl, SearchItems, Symbols } from '../static/apiModels';

import { drawRect, drawTriangle } from '@/helpers/MapCompute';

import {
  selectColor,
  convertHslToStr,
  convertHexToHsl
} from '@/helpers/helper';

@Component({
  name: 'LegendItem',
  components: {
    IconCircle,
    ActionButtons
  }
})
export default class LegendItem extends Vue {
  @Prop(Boolean) readonly vis!: boolean;
  @Prop(Boolean) readonly propCircl!: boolean;

  menu = false;
  LM = legendMod;
  AM = aufgabenModule;

  get legendGlobal() {
    return this.LM.legend;
  }

  get tagOrteResults() {
    return tagModule.tagOrteNum;
  }

  convertHsl(col: Hsl) {
    return convertHslToStr(col.h, col.s, col.l);
  }

  convertHexToHsl(col: string) {
    if (col.length > 7) {
      col = col.substring(0, 7);
    }
    const hsl = convertHexToHsl(col);
    return convertHslToStr(hsl[0] * 360, hsl[1], hsl[2]);
  }

  hslToObj(hsl: string) {
    const vals = hsl.substring(4, hsl.length - 1).split(',');
    return {
      h: vals[0],
      s: Number(vals[1].substring(0, vals[1].length - 1)) / 100,
      l: Number(vals[2].substring(0, vals[2].length - 1)) / 100,
      a: 1
    };
  }

  drawRect(size: number, border: number, color: string, encoded: boolean) {
    return drawRect(size, border, color, encoded, 1.0);
  }

  drawTriangle(size: number, border: number, color: string, encoded: boolean) {
    return drawTriangle(size, border, color, encoded, 1.0);
  }

  deleteLegendEntry(el: LegendGlobal, idx: number | null) {
    this.LM.deleteLegendEntry(el, idx);
    expData.removeEntry(el.id, el.name, el.type ? el.type : 0);
    this.AM.clearAntworten();
    this.$emit('callChange', el);
    if (this.legendGlobal.length === 0) this.updateVis();
  }

  onLegendChange(el: LegendGlobal) {
    this.$emit('callChange', el);
  }

  updateVis() {
    this.$emit('changeVis', !this.vis);
  }

  async splitPreset(el: LegendGlobal, idx: number) {
    const id = (el.content[0] as IGetPresetOrtTagResult).presetId;
    await tagModule.fetchTagOrtePreset({
      ids: [id],
      erhArt: legendMod.erhArtFilter
    });
    const tagIds = [...new Set(this.tagOrteResults.map((val) => val.tagId))];
    for (const id of tagIds) {
      const tag = this.tagOrteResults.find((val) => val.tagId === id);
      const legEntry = await this.LM.createLegendEntry({
        icon: Symbols.Circle,
        layer: L.layerGroup(),
        name: tag?.tagName ? tag.tagName : '',
        color: selectColor(null),
        radius: 20,
        content: this.tagOrteResults.filter((val) => val.tagId === id),
        type: SearchItems.Tag
      });
      this.LM.addLegendEntry(legEntry);
    }
    this.deleteLegendEntry(el, idx);
    this.$emit('callChange', el);
  }
}
