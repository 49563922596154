
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { legendMod } from '@/store/modules/legend';
import { messageHandler } from '@/store/modules/message';

@Component({
  name: 'AgeRange',
  components: {}
})
export default class AgeRange extends Vue {
  menu = false;
  age = 20;
  ages = [20, 70];
  LM = legendMod;
  MM = messageHandler;
  singleAge = false;
  onlyLower = false;

  get filterByAge() {
    return this.LM.filterByAge;
  }

  changeFilter() {
    this.LM.setFilterByAge(!this.filterByAge);
    if (this.filterByAge) {
      this.MM.setSuccessMsg({
        message: 'Daten werden nach Alter gefiltert',
        icon: 'mdi-info'
      });
    } else {
      this.MM.setSuccessMsg({
        message: 'Daten werden nicht nach Alter gefiltert',
        icon: 'mdi-info'
      });
    }
  }

  removeAge() {
    this.LM.setAgeRange({ lower: -1, upper: -1 });
    this.MM.setSuccessMsg({
      message: 'Filter wurde entfernt',
      icon: 'mdi-info'
    });
  }

  applyAge() {
    if (this.singleAge) {
      if (this.onlyLower) {
        this.LM.setAgeRange({ lower: this.age, upper: -1 });
        this.MM.setSuccessMsg({
          message: `Filtern nach mindestens ${this.age} Jahre alten Personen`,
          icon: 'mdi-info'
        });
      } else {
        this.LM.setAgeRange({ lower: -1, upper: this.age });
        this.MM.setSuccessMsg({
          message: `Filtern nach bis zu ${this.age} Jahre alten Personen`,
          icon: 'mdi-info'
        });
      }
    } else {
      const res = { lower: this.ages[0], upper: this.ages[1] };
      this.LM.setAgeRange(res);
      this.MM.setSuccessMsg({
        message: `Filtern nach Personen welche zwischen ${this.ages[0]} und ${this.ages[1]} Jahre alt sind`,
        icon: 'mdi-info'
      });
    }
  }
}
