
import { Prop, Vue, Component } from 'vue-property-decorator';
import { legendMod } from '@/store/modules/legend';
import { erhebungModule } from '@/store/modules/erhebungen';
import { messageHandler } from '@/store/modules/message';
import ErhebungDropdown from './ErhebungDropdown.vue';

@Component({
  name: 'ErhebungsArt',
  components: { ErhebungDropdown }
})
export default class ErhebungsArt extends Vue {
  menu = false;
  LM = legendMod;
  MM = messageHandler;
  erhArt: number[] = [];
  chips: any[] = [];

  get filterByArt() {
    return this.LM.filterByArt;
  }

  get erhLoading() {
    return erhebungModule.erhLoading;
  }

  get erhArten() {
    return erhebungModule.erhebungsarten;
  }

  changeArtFilter() {
    this.LM.setFilterByArt(!this.filterByArt);
    if (this.filterByArt) {
      this.MM.setSuccessMsg({
        message: 'Daten werden nach Erhebungsart gefiltert',
        icon: 'mdi-info'
      });
    } else {
      this.MM.setSuccessMsg({
        message: 'Daten werden nicht nach Erhebungsart gefiltert',
        icon: 'mdi-info'
      });
    }
  }

  removeArt() {
    this.LM.setFilterByArt(false);
    this.MM.setSuccessMsg({
      message: 'Filter wurde entfernt',
      icon: 'mdi-info'
    });
    this.menu = false;
  }

  applyFilter() {
    this.LM.setErhArtFilter([].concat(...this.chips.map((val) => val.id)));
    this.menu = false;
  }

  mounted() {
    if (!this.erhArten || this.erhArten.length === 0) {
    }
    erhebungModule.fetchErhebungsArten();
  }
}
