var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-slide-x-reverse-transition',[(_vm.vis)?_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","min-width":"300","height":"inherit"}},[_c('v-divider',{staticClass:"mx-4"}),_c('v-card-title',[_vm._v("Legende "),_c('v-spacer')],1),_c('v-card-text',{staticClass:"mx-auto"},[_c('v-list',{staticClass:"transparent"},[_vm._l((_vm.legendGlobal.filter((el) => el.type !== 3)),function(d,i){return _c('v-list-item',{key:d.id},[_c('v-list-item-icon',[_c('v-menu',{ref:"menu",refInFor:true,staticClass:"mx-auto pr-100",attrs:{"transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(d.symbol === 0 || !_vm.propCircl)?[_c('v-avatar',_vm._g({},on),[_c('icon-circle',{attrs:{"fillCol":_vm.convertHsl(d.color),"strokeWidth":d.strokeWidth}})],1)]:[_c('v-avatar',_vm._g({attrs:{"tile":""}},on),[(d.symbol === 1)?[_c('img',{attrs:{"src":_vm.drawRect(
                            7,
                            d.strokeWidth,
                            _vm.convertHsl(d.color),
                            true
                          )}})]:[_c('img',{attrs:{"src":_vm.drawTriangle(
                            7,
                            d.strokeWidth,
                            _vm.convertHsl(d.color),
                            true
                          )}})]],2)]]}}],null,true)},[[_c('v-card',[_c('v-card-title',[_vm._v("Farbe")]),_c('v-card-text',{on:{"click":function($event){return _vm.onLegendChange(d)}}},[_c('v-color-picker',{attrs:{"hide-inputs":""},model:{value:(d.color),callback:function ($$v) {_vm.$set(d, "color", $$v)},expression:"d.color"}})],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-title',[_vm._v("Durchmesser")]),_c('v-card-text',[_vm._v(" "+_vm._s(d.size)+" px "),_c('v-slider',{attrs:{"hint":"Durchmesser einstellen","min":"2","max":"100"},on:{"change":function($event){return _vm.onLegendChange(d)}},model:{value:(d.size),callback:function ($$v) {_vm.$set(d, "size", $$v)},expression:"d.size"}})],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-title',[_vm._v("Durchmesser Rand")]),_c('v-card-text',[_vm._v(" "+_vm._s(d.strokeWidth)+" px "),_c('v-slider',{attrs:{"hint":"Durchmesser von Strich einstellen","min":"1","max":"10"},on:{"change":function($event){return _vm.onLegendChange(d)}},model:{value:(d.strokeWidth),callback:function ($$v) {_vm.$set(d, "strokeWidth", $$v)},expression:"d.strokeWidth"}})],1)],1)]],2)],1),_c('v-list-item-content',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(d.name)+" ")]),_c('v-list-item-action',[_c('v-container',[_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteLegendEntry(d, i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Eintrag löschen")])]),_c('v-btn',{attrs:{"icon":"","color":"grey"},on:{"click":function($event){d.vis = !d.vis;
                    _vm.onLegendChange(d);}}},[(d.vis)?[_c('v-icon',[_vm._v("mdi-eye-outline")])]:[_c('v-icon',[_vm._v("mdi-eye-off-outline")])]],2),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(d.type === 6)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.splitPreset(d, i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-call-split")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Presettag aufteilen")])])],1)],1)],1)],1)}),_vm._l((_vm.legendGlobal.filter((el) => el.type === 3)),function(d,i){return _c('v-list-item',{key:d.id},[_c('v-list-item-content',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(d.name)+" "),_vm._l((d.parameter),function(para,idx){return _c('v-list-item',{key:idx},[_c('v-avatar',[_c('icon-circle',{attrs:{"fillCol":_vm.convertHexToHsl(
                      para.color !== undefined ? para.color : '#F00'
                    ),"strokeWidth":d.strokeWidth}})],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(para.name)+" ")])],1)})],2),_c('v-list-item-action',[_c('v-container',[_c('v-row',[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteLegendEntry(d, i)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ path: 'query', query: { legend: d.id } })}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"grey"},on:{"click":function($event){d.vis = !d.vis;
                    _vm.onLegendChange(d);}}},[(d.vis)?[_c('v-icon',[_vm._v("mdi-eye-outline")])]:[_c('v-icon',[_vm._v("mdi-eye-off-outline")])]],2)],1)],1)],1)],1)})],2)],1),_c('v-card-actions',[_c('action-buttons',{attrs:{"color":"indigo","showTrash":false},on:{"hideCard":function($event){return _vm.updateVis()},"moveCard":function($event){return _vm.$emit('moveCard', $event)}}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }