
import { Component, Vue } from 'vue-property-decorator';
import { messageHandler } from '@/store/modules/message';
import Message from '@/components/Message.vue';
import { SNACK_BAR_TIMEOUT } from '@/constants';

@Component({
  components: { Message },
  name: 'Snackbar'
})
export default class Snackbar extends Vue {
  progressTime: number = 0;

  get snackbar_timeout() {
    return SNACK_BAR_TIMEOUT;
  }

  get queue() {
    return messageHandler.queue;
  }

  get latestLogColor() {
    if (!this.queue || this.queue.length < 1) {
      return 'info';
    }
    return this.queue[this.queue.length - 1].type;
  }

  get time() {
    return messageHandler.activation;
  }

  get showSnackbar() {
    return messageHandler.showSnack;
  }

  mounted() {}
}
