
import { Component, Prop, Vue } from 'vue-property-decorator';
import { messageHandler } from '@/store/modules/message';

import { VueEditor } from 'vue2-editor';
import { legendMod } from '@/store/modules/legend';
import { SearchItems } from '@/static/apiModels';

@Component({
  components: { VueEditor },
  name: 'LegendDescEdit'
})
export default class LegendDescEdit extends Vue {
  @Prop(String) readonly description!: string;

  value: string = '';
  LM = legendMod;
  edit: boolean = false;

  get queryLegend() {
    return this.LM.legend.filter((el) => el.type === SearchItems.Query);
  }

  created() {
    this.value = this.description;
  }

}
