
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { messageHandler } from '@/store/modules/message';

import { legendMod } from '@/store/modules/legend';
import { exportLegend, localStorageQuery, SearchItems } from '@/static/apiModels';
import { expData } from '@/service/ExportBase';

import ItemDescription from './ItemDescription.vue';
import IconCircle from '@/icons/IconCircle.vue';
import { convertHexToHsl, convertHslToStr, fetchContent } from '@/helpers/helper';
import { LayerGroup } from 'leaflet';

@Component({
  components: { ItemDescription, IconCircle },
  name: 'QueryHistory'
})
export default class QueryHistory extends Vue {
  // queries: localStorageQuery[] = [];
  headers = [
    { text: 'Query', value: 'legend' },
    { text: 'Erzeugt am', value: 'date' },
    { text: 'Gelöscht', value: 'deleted' },
    { text: 'Aktionen', value: 'actions' },
  ];

  LM = legendMod;

  get legendGlobal() {
    return legendMod.legend;
  }

  get queries() {
    return legendMod.localStorageLegend;
  }

  convertHslToStr(h: number, s: number, l: number) {
    if (h <= 1) h *= 360;
    return convertHslToStr(h, s, l);
  }

  deleteHistory() {
    this.LM.removeDeletedEntries();
    expData.deleteQueryLocalStorage();
    this.LM.resetLocalStorage();
    expData.setQueryLocalStorage(this.queries);
  }

  changeLegendVis(id: string) {
    let legIdx = this.queries.findIndex(el => el.legend.id === id);
    if (legIdx !== undefined) {
      let editLeg = this.queries[legIdx].legend;
      editLeg.vis = !editLeg.vis;
      const legFilter = this.legendGlobal.filter(el => el.id === editLeg.id);
      if (legFilter.length !== 1) return;
      legFilter[0].vis = !legFilter[0].vis;
      this.LM.editLegendByID(legFilter[0]);
      this.queries[legIdx].legend = editLeg;
      expData.editLocalStorageEntry(this.queries, editLeg.id, this.queries[legIdx]);
    }
  }

  async changeDelLegend(leg: exportLegend, deleted: boolean) {
    leg.vis = deleted;
    let ele;
    if (deleted) {
      expData.markAsDeleted(leg.id, leg.type ? leg.type : SearchItems.Tag, false);
      // Fetch the needed content for the legend
      const res = await fetchContent(leg.elementId, leg.type);
      // create the new entry
      const lm = await this.LM.createLegendEntry({
        icon: leg.symbol,
        layer: new LayerGroup(),
        name: leg.name,
        color: leg.color,
        radius: leg.size,
        content: leg.type === SearchItems.Ort ? leg.content : res,
        type: leg.type,
        id: leg.id
      });
      lm.parameter = leg.type === SearchItems.Query ? leg.parameter : null;
      ele = lm;
      this.LM.addLegendEntry(lm);
    } else {
      expData.markAsDeleted(leg.id, leg.type ? leg.type : SearchItems.Tag, true);
      const lm = this.legendGlobal.find(el => el.id === leg.id);
      ele = lm;
      if (ele === undefined) return;
      this.LM.deleteLegendEntry(ele, null);
    }
    this.$emit('callChange', ele);
    // this.queries = expData.getQueryFromLocalStorage();
  }

  mounted() {

    this.queries.forEach(el => {
      const idx = this.legendGlobal.findIndex(ele => el.legend.id === ele.id || (ele.type === el.legend.type && ele.name === el.legend.name))
      if (this.legendGlobal.length === 0 || idx < 0) {
        el.deleted = true;
        return;
      } else {
        el.deleted = false;
        el.legend.color = this.legendGlobal[idx].color;
      }
    });
  }
}
