
import { Component, Vue, Prop } from 'vue-property-decorator';
import { tagModule } from '@/store/modules/tags';
import { TagTree } from '@/api/dioe-public-api';
import { SingleTag, TagSelection } from '@/static/apiModels';
import { convertHexToHsl } from '@/helpers/helper';

@Component({
  // if you use components add them here
  components: {},
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: 'TagViewSelect'
})
export default class TagViewSelect extends Vue {
  TM = tagModule;
  showMenu: boolean = false;
  hover: boolean = false;
  x = 0;
  y = 0;

  @Prop() readonly children!: TagTree[];
  @Prop() readonly tagData!: SingleTag;
  @Prop() readonly generation!: number;
  @Prop() readonly tagSelection!: TagSelection;
  @Prop({ default: '#F00', type: String }) readonly color!: string;
  @Prop({ default: true, type: Boolean }) readonly editMode!: boolean;
  @Prop({ default: false, type: Boolean }) readonly topTag!: boolean;


  get tagSelectioAll() {
    return this.TM.tagSelection;
  }

  get textColor(): boolean {
    const hsl = convertHexToHsl(this.color);
    return hsl[2] < 0.4;
  }

  tagSel(tag: SingleTag) {
    return {
      parentId: this.tagSelection.parentId,
      children: this.fetchChildren(tag.tagId),
      tagGroup: tag,
      tagIds: this.tagSelection.tagIds
    } as TagSelection;
  }

  fetchChildren(id: number) {
    if (this.tagSelection) {
      return this.tagSelection.children.find((el) => el.tagId === id)?.children;
    } else {
      return this.children.find((el) => el.tagId === id)?.children;
    }
  }

  addChildTag() {
    let name = this.tagSelection.tagName;
    if (!this.tagSelection.tagName) {
      name = this.tagSelection.tagGroup.tagName;
    }
    this.TM.setAutoCompleteLabel(`Kindtag für ${name} hinzufügen`);
    this.TM.setChildrenTag(this.tagSelection.children);
  }

  bus(val: number) {
    this.$emit('bus', val);
    this.$emit('deleteTag', val);
  }

  deleteTags() {
    this.TM.setAutoCompleteLabel(`Neuen Tag hinzufügen`);
    // this.TM.deleteTag(this.tagData.tagId);
    this.$emit('bus', this.tagData.tagId);
    this.$emit('deleteTag', this.tagData.tagId);
  }

  show(e: any) {
    e.preventDefault();
    this.showMenu = false;
    this.x = e.clientX;
    this.y = e.clientY;
    this.$nextTick(() => {
      this.showMenu = true;
    });
  }
}
