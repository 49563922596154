
import { Component, Prop, Vue } from 'vue-property-decorator';
import { messageHandler } from '@/store/modules/message';
import { SNACK_BAR_TIMEOUT } from '@/constants/index';

@Component({
  components: {},
  name: 'Message'
})
export default class Message extends Vue {
  @Prop({ type: String, default: 'mdi-information-outline' })
  readonly icon!: string;
  @Prop(String) readonly msg!: string;
  @Prop({ type: Number, default: null }) readonly date!: number | null;

  progressTime: number = 0;

  closeMsg() {
    messageHandler.setSingleShow({ shw: false, id: this.date ? this.date : 0 });
  }

  created() {
    if (this.date && Date.now() - this.date < SNACK_BAR_TIMEOUT) {
      const intvId = setInterval(() => {
        if (this.date) {
          const intv = Date.now() - this.date;
          if (intv >= SNACK_BAR_TIMEOUT) {
            console.log('Closing');
            this.closeMsg();
            clearInterval(intvId);
          }
          this.progressTime = 100 * (intv / SNACK_BAR_TIMEOUT);
        } else {
          clearInterval(intvId);
        }
      }, 200);
    }
  }
}
