
import { Component, PropSync, Vue, Prop, Watch } from 'vue-property-decorator';
import { Description, pinDataVar, Symbols } from '@/static/apiModels';
import GraphViewer from './GraphViewer.vue';
import ActionButtons from './ActionButtons.vue';
import { legendMod } from '@/store/modules/legend';

@Component({
  components: { GraphViewer, ActionButtons },
  name: 'VariationCard'
})
export default class VariationCard extends Vue {
  @Prop() readonly data!: pinDataVar;
  @Prop(Boolean) readonly loading!: boolean;

  LM = legendMod;

  pinCard(event: any, pinData: boolean) {
    let d = this.data;
    d.isPinned = pinData;
    this.LM.editPinDataByID(d);
  }

  removeElement() {
    this.LM.removeElementFromPinDataVarById(this.data.id);
  }
}
