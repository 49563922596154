
import { Component,Vue } from 'vue-property-decorator';
import Darkbtn from './Darkbtn.vue';
@Component({
  // if you use components add them here
  components: { Darkbtn },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: 'Navigation'
})
export default class Navigation extends Vue { }
