
import { AntwortKontext } from '@/api/dioe-public-api';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'KontextView'
})
export default class KontextView extends Vue {
  @Prop() readonly kontextData!: AntwortKontext[];
  @Prop(Number) readonly focusTag!: number;
  @Prop(String) readonly focusOrtho!: string;
  sigle = null as any;

  mounted() {
    this.sigle = new Set(this.kontextData.map((el) => el.sigle));
  }
}
