
import { Prop, Vue, Component, PropSync } from 'vue-property-decorator';
import { selectionObject } from '@/api/dioe-public-api';

@Component({
  name: 'TokenChips',
  components: {}
})
export default class TokenChips extends Vue {
  @Prop() readonly selectedElements!: selectionObject[];
  @Prop({ type: String, default: '#FF0000' }) readonly color!: string;
  @Prop(Boolean) readonly edit!: boolean;

  value: selectionObject[] = [];

  created() {
    this.value = this.selectedElements;
  }

  deleteItem(idx: number) {
    const el = this.selectedElements.length === 1 ? [] as selectionObject[] : this.selectedElements.splice(idx, 1);
    this.$emit('update:selectedElements', el);
  }
}
