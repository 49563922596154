

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'ActionButtons'
})
export default class DragableCard extends Vue {
  @Prop({
    type: String, default: 'indigo'
  }) readonly color!: string;
  @Prop({ type: Boolean, default: false }) readonly pinned!: boolean;
  @Prop({ type: Boolean, default: false }) readonly showPin!: boolean;
  @Prop({ type: Boolean, default: true }) readonly showTrash!: boolean;
}
