var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":800,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-5",attrs:{"elevation":"1","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)]}}])},[_c('v-card',[_c('v-tabs',{attrs:{"background-color":"indigo","dark":"","grow":""},model:{value:(_vm.optionTab),callback:function ($$v) {_vm.optionTab=$$v},expression:"optionTab"}},[_c('v-tab',[_vm._v("Weitere Optionen ")]),_c('v-tab',[_vm._v("Aufgabensets")]),_c('v-tab',[_vm._v("Aufgaben")])],1),_c('v-tabs-items',{model:{value:(_vm.optionTab),callback:function ($$v) {_vm.optionTab=$$v},expression:"optionTab"}},[_c('v-tab-item',[_c('v-card',[_c('v-card-title',[_vm._v("Weitere Optionen")]),_c('v-divider'),_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"mb-12",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"color":"indigo"}},_vm._l((_vm.filterOptionMenu),function(d,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.openFilter(d.type)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(d.name)+" ")]),_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)}),1)],1)],1),_c('v-col',[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"max-height":"300px"}},[(_vm.filterMenuValue.length > 0)?_c('v-list-item-group',{attrs:{"color":"indigo","multiple":""},model:{value:(_vm.phaenSelection),callback:function ($$v) {_vm.phaenSelection=$$v},expression:"phaenSelection"}},_vm._l((_vm.filterMenuValue),function(val,i){return _c('div',{key:i},[_c('v-list-item',[_vm._v(" "+_vm._s(val.content[val.name])+" ")]),_c('v-divider')],1)}),0):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(
                _vm.filterMenuValue.length > 0 &&
                _vm.filterMenuValue[0].type === 2 &&
                _vm.phaenSelection.length > 0
              )?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.searchAufgabeByPhaen()}}},[_vm._v(" Nach Aufgabensets suchen ")]):_vm._e()],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"loading":_vm.aufgabenLoading}},[_c('v-card-title',[_vm._v("Aufgabensets")]),_c('v-divider'),_c('v-card-text',[_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"mb-12",attrs:{"no-gutters":""}},[_c('v-col',[(_vm.asetPhaen.length > 0)?[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"multiple":"","color":"indigo"},model:{value:(_vm.asetSelection),callback:function ($$v) {_vm.asetSelection=$$v},expression:"asetSelection"}},_vm._l((_vm.asetPhaen.filter(
                            (el) => el.id !== -1
                          )),function(d,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(" Name: "+_vm._s(d.name)+" ")])],1)}),1)],1)]:[_vm._v(" Keine Aufgabensets vorhanden! ")]],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.asetPhaen.length > 0)?_c('v-btn',{attrs:{"color":"primary","disabled":!(_vm.asetSelection.length > 0)},on:{"click":function($event){return _vm.displayOnMap()}}},[_vm._v(" Aufgabenset auf der Karte anzeigen ")]):_vm._e()],1)],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-card-title',[_vm._v("Aufgaben")]),_c('v-divider'),_c('v-card-text',[_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"mb-12",attrs:{"no-gutters":""}},[_c('v-col',[(_vm.asetPhaen.length > 0)?[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"max-height":"300px"}},[_c('v-list-item-group',{attrs:{"multiple":"","color":"indigo"},model:{value:(_vm.aufgabenSelection),callback:function ($$v) {_vm.aufgabenSelection=$$v},expression:"aufgabenSelection"}},_vm._l((_vm.asetPhaen.filter(
                            (el) => el.id === -1
                          )[0].aufgaben),function(d,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(" Aufgabenstellung: "+_vm._s(d.aufgabenstellung)+" "),_c('br'),_vm._v(" Beschreibung: "+_vm._s(d.beschreibung)+" ")])],1)}),1)],1)]:[_vm._v(" Keine Aufgaben vorhanden! ")]],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.asetPhaen.length > 0)?_c('v-btn',{attrs:{"color":"primary","disabled":!(_vm.aufgabenSelection.length > 0)},on:{"click":function($event){return _vm.displayAufgabeOnMap()}}},[_vm._v(" Ausgewählte Aufgaben auf der Karte anzeigen ")]):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }