
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: "IconCircle",
})
export default class IconCircle extends Vue {
  @Prop({ type: [Number, String], default: 12 }) readonly radius:
    | number
    | string
    | undefined;
  @Prop({ type: String, default: "black" }) readonly strokeColor!: string;
  @Prop({ type: Number, default: 1 }) readonly strokeWidth!: number;
  @Prop({ type: String, default: "black" }) readonly fillCol!: string;
  height: number = 0;
  width: number = 0;

  mounted() {
    this.width = (Number(this.radius) + 1) * 2;
    this.height = this.width;
  }
}
